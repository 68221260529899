<template>
    <div class="detail-info">
		<!-- 返回 -->
		<span class="returnText">
			<img src="https://z3.ax1x.com/2021/08/11/fNWDAJ.png" style="width: 19px; height: 19px;" v-on:click="back">
			<div style="font-size:20px;" v-on:click="back">返回</div>
		</span> 
        <!-- 标题 -->
        <div class="info-title"> 
			{{detailInfo.name}}
		</div>
        <!-- 内容 -->
        <div class="info-content">
            <!-- 左边 -->
            <div class="content-lf">
                <slot name="contentLf"></slot>
                <div class="lf-html" v-html="detailInfo.summary"></div>
            </div>
            <!-- 右边 -->
            <div class="content-rt">
                <!-- 价格 -->
                <div class="rt-item">
                    <p>价格：</p>
                    <p>
                        <span class="blue">{{price}}</span>
                        元
                    </p>
                </div>
                <!-- 种类 -->
                <div class="rt-item">
                    <p>种类：</p>
                    <p>{{detailInfo.type}}</p>
                </div>
				<!-- 库存 -->
				<div class="rt-item">
				    <p>库存：</p>
				    <p>剩余{{detailInfo.stock}}件</p>
				</div>
                <!-- 发布日期 -->
                <div class="rt-item">
                    <p>发布日期：</p>
                    <p>{{detailInfo.release}}</p>
                </div>
                <!-- 编号 -->
                <div class="rt-item">
                    <p>编号：</p>
                    <p>{{detailInfo.identifier}}</p>
                </div>
                <!-- 规格 -->
                <slot name="specs"></slot>
                <!-- 设置尺寸 -->
                <slot name="setSize"></slot>
                <!-- 下单人数 -->
                <div class="rt-sell">
                    已有
                    <span class="blue">{{detailInfo.sell_out}}</span>
                    人下单
                </div>
                <!-- 下单 -->
                <el-button
                    type="primary"
                    @click="handleClick"
                    class="rt-btn">
                    立即下单
                </el-button>
                <!-- 版权 -->
                <div class="rt-statement">
                    版权所有：吉享控件已获取作者授权，有权就作品进行销售
                </div>
                <!-- 发布者 -->
                <div class="rt-title">
                    发布者信息
                </div>
                <div class="rt-owner">
                    <!-- 头像 -->
                    <el-image
                        :src="ownerInfo.avatar"
                        fit="scale-down"
                        class="owner-avatar">
                        <!-- 加载中样式 -->
                        <template v-slot:placeholder>
                            <div class="image-slot">
                                <i class="el-icon-loading"></i>
                            </div>
                        </template>
                        <!-- 加载失败样式 -->
                        <template v-slot:error>
                            <div class="image-slot">
                                <i class="el-icon-picture-outline"></i>
                            </div>
                        </template>
                    </el-image>
                    <div class="owner-desc">
                        <div class="owner-info">
                            <span class="owner-name">{{ownerInfo.name}}</span>
                            <span :class="[
                                'owner-gender',
                                {'owner-male': ownerInfo.gender == 1},
                                {'owner-female': ownerInfo.gender == 0}
                            ]">
                            </span>
                        </div>
                        <p class="owner-signature">
                            {{ownerInfo.signature}}
                        </p>
                    </div>
                </div>
                <!-- ta的相关发布 -->
                <div class="rt-title">
                    ta的相关发布
                </div>
                <!-- 相关 -->
                <div class="rt-relevant">
                    <template v-for="(item, index) in ownerInfo.other_goods">
                        <router-link 
                            :key="index"
                            :to="{
                                name: otherRoute,
                                query: {
                                    id: item.id
                                }
                            }"
                            class="relevant-item">
                            <el-image
                                :src="item.img"
                                fit="scale-down"
                                class="relevant-pic">
                                <!-- 加载中样式 -->
                                <template v-slot:placeholder>
                                    <div class="image-slot">
                                        <i class="el-icon-loading"></i>
                                    </div>
                                </template>
                                <!-- 加载失败样式 -->
                                <template v-slot:error>
                                    <div class="image-slot">
                                        <i class="el-icon-picture-outline"></i>
                                    </div>
                                </template>
                            </el-image>
                        </router-link>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            price: [String, Number],
            detailInfo: Object,
            imgList: Array,
            ownerInfo: Object,
            otherRoute: String,
			datum:Object,
        },
		watch:{
			datum(){
				if(this.datum){
					if(this.datum.page){
						sessionStorage.setItem('search', this.datum.search=="function search() { [native code] }"?' ':this.datum.search || ' ');
						sessionStorage.setItem('type', this.datum.type || 0);
						sessionStorage.setItem('style', this.datum.style || 0);
						sessionStorage.setItem('sort', this.datum.sort || 0);
						sessionStorage.setItem('page', this.datum.page || 1);
						console.log("触发1",this.datum)	
					}else{
						sessionStorage.setItem('search', ' ');
						sessionStorage.setItem('type', 0);
						sessionStorage.setItem('style', 0);
						sessionStorage.setItem('sort', 0);
						sessionStorage.setItem('page', 1);
						console.log("触发3")	
					}
					
				}else {
					sessionStorage.setItem('search', ' ');
					sessionStorage.setItem('type', 0);
					sessionStorage.setItem('style', 0);
					sessionStorage.setItem('sort', 0);
					sessionStorage.setItem('page', 1);
					console.log("触发2",this.datum)
				}
			}
		},
		mounted() {
			
		},
        methods: {
			  back(){    
				// this.$router.go(-1);//返回上一层
				// console.log(this.datum)	
				// let search = this.datum.search=="function search() { [native code] }"?'':this.datum.search,
				//  type   = this.datum.type ||  0,
				//  style  = this.datum.style || 0,
				//  sort   = this.datum.sort || 0,
				//  page   = this.datum.page || 0
			
				let search = sessionStorage.getItem("search") || '',
					type   = sessionStorage.getItem("type") || 0,
					style  = sessionStorage.getItem("style") || 0,
					sort   = sessionStorage.getItem("sort") || 0,
					page   = sessionStorage.getItem("page") || 1
				
				 this.$router.push({name:'metopeList',query:{search:search,type:type,style:style,sort:sort,page:page}}) // 只能用 name
			 },
            handleClick(){
                this.$emit('handleClick')
            }
        },
    }
</script>

<style scoped>
.returnText{
  font-size:20px;
  margin-right: 20px;
  margin-bottom:20px ;
  display: flex;
  align-items: center;	
}
.detail-info {
    margin-top: 20px;
	padding: 20px;
	background-color: #fff;
}
.detail-info .blue {
    color: rgb(17, 66, 168);
	font-size: 18px;
}
/* 标题 */
.info-title {
	margin-bottom: 20px;
    font-size: 20px;
}
/* 内容容器 */
.info-content {
    display: flex;
    align-items: stretch;
}
/* 左边 */
.content-lf {
    flex-shrink: 0;
    width: 60%;
    margin-right: 100px;
}
.lf-html {
    margin-top: 30px;
}
/* 右边 */
.content-rt {
    flex: 1;
}
.rt-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
	margin-bottom: 16px;
}
.rt-sell {
    margin-top: 20px;
    margin-bottom: 6px;
    text-align: right;
}
.rt-btn {
    width: 100%;
    height: 40px;
    background-color: rgb(17, 66, 168);
    border-radius: 4px;
    letter-spacing: 4px;
}
.rt-statement {
    margin-top: 6px;
    margin-bottom: 50px;
    font-size: 12px;
    font-weight: lighter;
	color: #bbb;
}
.rt-owner {
    display: flex;
    align-items: center;
    height: 100px;
}
.owner-avatar {
    width: 60px;
    height: 60px;
    margin-right: 6px;
    border-radius: 50%;
    overflow: hidden;
}
/* 拥有者描述信息 */
.owner-desc {
    flex: 1;
    width: 0;
}
/* 信息 */
.owner-info {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
}
.owner-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.owner-gender {
    flex-shrink: 0;
    width: 16px;
    height: 16px;
}
.owner-male {
    background-image: url('../../assets/icons/icon_male.png');
}
.owner-female {
    background-image: url('../../assets/icons/icon_girl.png');
}
/* 签名 */
.owner-signature {
    overflow: hidden;
    text-overflow: ellipsis;
    color: rgb(187, 187, 187);
}
.rt-relevant {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
	grid-row-gap: 6px;
	grid-column-gap: 6px;
	grid-template-rows: repeat(2, 1fr);
}
.relevant-item {
    position: relative;
    width: 100%;
}
.relevant-item::before {
    display: block;
    content: "";
    padding-top: 100%;
}
.relevant-pic {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #f5f7fa;
}
</style>